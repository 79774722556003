@mixin plus-rectangle {
  content: '';
  position: absolute;
  width: 1px;
  height: 7px;
  background-color: #5d78ff;
  z-index: 5;
}

@mixin button-reset {
  padding: 0;
  outline: none;
  background: none;
  border: none;
  cursor: pointer;
}

@mixin breadcrumb-arrow {
  content: '';
  position: absolute;
  width: 1px;
  height: 5px;
  background-color: $nav-item;
}

$max: 50;
$offset: 1;
@mixin loop($className, $styleName) {
  $i: 0;
  @while $i <= $max {
    #{$className + $i} {
      #{$styleName}: #{$i + "px!important"};
    }
    $i: $i + $offset;
  }
}

@include loop(".m-t-", "margin-top");
@include loop(".m-r-", "margin-right");
@include loop(".m-b-", "margin-bottom");
@include loop(".m-l-", "margin-left");
@include loop(".m-x-", "margin");