.bottombar {
  height: 58px;
  width: calc(100% - 265px);
  background: transparent linear-gradient(2deg, #ffffff 0%, #ffffff 100%) 0% 0%
    no-repeat padding-box;
  box-shadow: 0px 0px 13px #523f690c;
  position: fixed;
  bottom: 0;
  margin-left: -20px;
  margin-right: 100px;
  padding: 20px;
  display: flex;
  align-items: center;
  &__btn-container {
    &--mr {
      margin-right: auto;
    }
    &--ml {
      margin-left: 20px;
    }
    svg {
      transform: translate(-5px, -1px);
    }
  }
}
