.main-container {
  margin-left: 265px;
  width: calc(100% - 265px);
}

.main-navbar {
  width: calc(100% - 265px);
  position: fixed;
  z-index: 800;
  top: 0;
  background: linear-gradient(180deg, $purple-light 0%, $purple-dark 100%) 0% 0%;
  &__top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 8px;
    padding-top: 8px;
  }
  &__bottom {
    position: relative;
    padding: 0 8px;
    min-height: 48px;
    padding-top: 10px;
    z-index: 10;
    overflow: hidden;
    &:after {
      content: '';
      position: absolute;
      width: 100%;
      background-color: black;
      top: 0;
      left: 0;
      opacity: 0.1;
      z-index: -1;
    }
    .tab-list {
      display: flex;
      &__text {
        white-space: nowrap;
      }
      &__item {
        flex-basis: 198px;
        cursor: default;
        border-radius: 4px 4px 0px 0px;
        background-color: rgba(255, 255, 255, 0.2);
        margin-right: 4px;
        transition: 0.3s;
        a {
          padding: 4px 10px;
          display: flex;
          align-items: center;
        }
        &:hover {
          background-color: rgba(255, 255, 255, 0.3);
        }
        &--active {
          background-color: $tab-active;
          &:hover {
            background-color: white;
          }
          path {
            fill: #5d78ff;
          }
          .svg-rect {
            fill: #5d78ff;
          }
          .tab-list__text {
            color: $tab-text-active;
          }
          .tab-list__close {
            &:before {
              background-color: $tab-text-active;
            }
            &:after {
              background-color: $tab-text-active;
            }
          }
        }
      }
      &__close {
        @include button-reset;
        width: 30px;
        height: 30px;
        margin-left: auto;
        position: relative;
        cursor: pointer;
        &:before {
          content: '';
          width: 1px;
          height: 12px;
          background-color: white;
          position: absolute;
          transform: translate(-50%, -50%) rotate(45deg);
        }
        &:after {
          content: '';
          width: 1px;
          height: 12px;
          background-color: white;
          position: absolute;
          transform: translate(-50%, -50%) rotate(-45deg);
        }
      }
    }
  }
  &__app-name {
    display: flex;
    align-items: center;
    cursor: default;
  }
}

.nav-button {
  display: flex;
  align-items: center;
  @include button-reset;
  transition: 0.3s;
  &:hover {
    .nav-button__logo::after {
      opacity: 0.2;
    }
  }
  &__logo {
    position: relative;
    &--right {
      order: 2;
    }
    &:after {
      content: '';
      position: absolute;
      transition: 0.3s;
      top: 0;
      left: 0;
      border-radius: 5px;
      width: 24px;
      height: 24px;
      background-color: #fff;
      opacity: 0.1;
    }
  }
}

.breadcrumbs {
  height: 30px;
  padding: 8px 8px;
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 5px;
  background-color: #fff;
  box-shadow: 0px 0px 13px #523f690c;
  &__button {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 2px;
    width: 16px;
    height: 16px;
    background: #f0f3ff 0% 0% no-repeat padding-box;
    svg {
      max-width: 10px;
    }
  }
  li {
    position: relative;
  }
  li:after {
    @include breadcrumb-arrow;
    top: 6px;
    right: 10px;
    transform: rotate(-45deg);
  }
  li:before {
    @include breadcrumb-arrow;
    top: 9px;
    right: 10px;
    transform: rotate(45deg);
  }
  li:last-child {
    &:before,
    &:after {
      display: none;
    }
  }
  &__list {
    display: flex;
    margin-left: 15px;
  }
  &__item {
    margin-right: 21px;
    @include button-reset;
    cursor: pointer;
  }
}
