.form-row {
  display: flex;
  justify-content: flex-start;
  width: calc(100% + 20px);
  margin-left: -10px;
  .form-group {
    margin: 0 10px;
  }
  &:last-child {
    .form-group {
      margin-bottom: 0;
    }
  }
  &.grid-2 {
    .form-group {
      flex-basis: calc(50% - 10px);
    }
  }
  &.grid-3 {
    .form-group {
      flex-basis: calc(33.3% - 20px);
    }
  }
}

@import './inputs.scss';

.form-row {
  &__select {
    background-color: white;
    cursor: pointer;
    width: 100%;
    padding: 11px;
    border-radius: 4px;
    border: none;
    border: 1px solid $input-form-border;
    color: #495057;
    font-size: 13px;
    font-family: 'Poppins', sans-serif;
    -moz-appearance: none; /* Firefox */
    -webkit-appearance: none; /* Safari and Chrome */
    appearance: none;
  }
}
