.form-group {
  flex-basis: 100%;
  max-width: 100%;
  &:not(:last-child) {
    margin-bottom: 20px;
  }
  label {
    display: inline-block;
    width: 100%;
    margin-bottom: 5px;
  }
  .inp-group {
    input {
      padding: 9px;
      border-radius: 4px;
      border: none;
      border: 1px solid $input-form-border;
      width: 100%;
      outline: none;
      &[type='number']::-webkit-inner-spin-button,
      &[type='number']::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }
    position: relative;
    overflow: hidden;
    display: flex;
    .form-span {
      display: block;
      position: relative;
      white-space: nowrap;
      font-size: 13px;
      font-family: 'Poppins';
      color: #495057;
      top: 0;
      bottom: 0;
      background-color: #f7f8fa;
      border: 1px solid #e2e5ec;
      padding: 10px;
    }
    .postfix {
      right: 0px;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      margin-left: -3px;
      &-color {
        width: 16px;
        height: 16px;
        background-color: #44b2f1;
        border: 1px solid #e2e5ec;
      }
    }
    .prefix {
      z-index: 1;
      margin-right: -3px;
      left: 0px;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }
  }
  &.save-group {
    display: flex;
    .btn-save {
      position: relative;
      width: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 3px;
      margin-left: -5px;
      background-color: #0abe8c;
      box-shadow: 0px 3px 16px #0abe8c26;
      transition: 0.2s;
      svg {
        max-width: 20px;
      }
      &[disabled] {
        opacity: 0;
        pointer-events: none;
      }
      &:hover {
        background-color: rgba($color: #0abe8c, $alpha: 0.6);
      }
      &:active {
        transform: scale(0.95);
      }
    }
    .inp-group {
      input {
        max-width: 90px;
        min-width: 75px;
        padding-right: 30px;
      }
      &::before {
        content: '';
        background-position: center;
        background-size: contain;
        position: absolute;
        top: 50%;
        right: 15px;
        transform: translateY(-50%);
      }
      &.success {
        &::before {
          width: 9px;
          height: 9px;
          background-image: url('../images/icons/inp-success.svg');
        }
        input {
          border-color: #0abb87;
        }
      }
      &.error {
        &::before {
          width: 11px;
          height: 9px;
          background-image: url('../images/icons/inp-error.svg');
        }
        input {
          border-color: #fd397a;
        }
      }
    }
  }
}

.input-disabled {
  background-color: #f7f8fa;
  font-size: 13px;
  font-family: 'Poppins';
}
