.categories {
  color: $table-head;
  padding: 20px;
  padding-top: 0px;
  &__item {
    width: 100%;
    //padding: 20px 0;
  }
  ul {
    &.child--1 {
      position: relative;
      &::before {
        content: '';
        position: absolute;
        z-index: 200;
        top: 10px;
        bottom: 5px;
        left: 0;
        border-left: 1px dashed rgba(89, 125, 255, 0.4);
        transform: translate(2px, -30px);
      }
      & > li > table {
        position: relative;
        &::before {
          content: '';
          z-index: 200;
          top: 47%;
          left: 23px;
          position: absolute;
          width: 16px;
          transform: translateY(-50%);
          border-bottom: 1px dashed rgba(89, 125, 255, 0.4);
        }
      }
      & > li tr td:first-child {
        padding-left: 60px;
      }
    }
    &.child--2 {
      position: relative;
      &::before {
        content: '';
        z-index: 200;
        position: absolute;
        top: 10px;
        bottom: 5px;
        left: 30px;
        border-left: 1px dashed rgba(89, 125, 255, 0.4);
        transform: translate(2px, -30px);
      }
      & > li {
        position: relative;
        &::before {
          content: '';
          z-index: 200;
          top: 45%;
          left: 32px;
          position: absolute;
          width: 16px;
          transform: translateY(-50%);
          border-bottom: 1px dashed rgba(89, 125, 255, 0.4);
        }
      }
      & > li tr td:first-child {
        padding-left: 90px;
      }
    }
  }
  table {
    td {
      padding: 20px 0;
    }
    tr {
      td:first-child {
        padding-left: 30px;
        width: 380px;
      }
    }
    //display: inline;
    //margin: 40px 0;
    //padding: 25px 0;
    width: calc(100% + 40px);
    margin-left: -20px;
    border-bottom: 1px solid $separator;
  }
}
