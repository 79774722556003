.heading.blocked {
  color: #fd397a;
  .error{
    margin-left: 20px;
    font-size: 20px;
    line-height: 25px;
    text-transform: uppercase;
  }
}

.supply-table {
  &.blocked {
    pointer-events: none;
    position: relative;
    &::before {
      content: '';
      position: absolute;
      z-index: 2;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: rgba(0, 0, 0, 0.2);
    }
  }
  &__header {
    padding: 25px 27px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    strong {
      font-weight: 500;
    }
    &__document-info {
      display: flex;
      align-items: center;
      p {
        margin-left: 50px;
      }
    }
  }
  tr {
    vertical-align: middle;
    td {
      padding: 0;
      font-weight: 300;
      vertical-align: middle;
      max-width: 300px;
      padding-right: 10px;
    }
  }
  .number {
    width: 75px;
    span {
      display: inline-block;
      line-height: 17px;
      min-width: 17px;
      padding: 0 5px;
      text-align: center;
      border-radius: 50%;
    }
    &.success {
      span {
        background-color: #daf5ed;
        color: #0abb87;
      }
    }
  }
  .image-box {
    width: 70px;
    border-radius: 4px;
    margin-right: 10px;
    font-size: 0;
    line-height: 0;
    .modal__trigger {
      width: 60px;
      height: 60px;
      border-radius: 4px;
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      overflow: hidden;
      position: relative;
      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba($color: #5d78ff, $alpha: 0.8);
        opacity: 0;
        transition: 0.2s;
        z-index: 1;
      }
      &::after {
        content: url('../images/icons/zoom.svg');
        position: absolute;
        z-index: 2;
        top: 50%;
        left: 50%;
        opacity: 0;
        transition: 0.2s;
        transform: translate(-50%, -50%);
      }
      &:hover {
        &::before,
        &::after {
          opacity: 1;
        }
      }
    }
  }
}
