.dashboard {
  width: 100%;
  background-color: white;
  box-shadow: 0px 0px 13px #523f690c;
  border-radius: 5px;
  margin:  0 0 20px;
  margin-bottom: 8px;
  &--small {
    flex-basis: 49.2%;
  }
  &__button-section {
    padding: 20px 30px;
  }
  &__list {
    &-item {
      display: flex;
      align-items: center;
      padding: 20px 30px;
      position: relative;
      .country {
        margin-right: auto;
        &-label--hidden {
          visibility: hidden;
        }
      }
      .no-margin {
        margin: 0;
      }
      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: $separator;
      }
    }
  }
  &__form {
    padding: 30px;
  }
  &__channel-label {
    padding: 30px;
    padding-top: 0;
    .channel-label-text {
      margin-bottom: 11px;
    }
  }
  &__header {
    display: flex;
    padding: 20px 30px;
    align-items: center;
    position: relative;
    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: $separator;
    }
    &--edit {
      padding: 18px 30px;
    }
  }
  &__title {
    margin-right: auto;
    margin-left: 10px;
  }
  &__searchfield {
    padding: 18px 30px;
    display: flex;
    .search-box {
      flex: 1;
      padding: 10px;
      border: 1px solid #e2e5ec;
      border-radius: 4px;
    }
  }
}

.dashboard-container {
  width: 100%;
  margin-top: 30px;
  min-height: calc(100vh - 30px);
  //min-height: 100vh;
  background-color: $dashboard-background;
  padding: 20px;
  padding-top: 80px;
  padding-bottom: 70px;
}

.selectbox {
  border: none;
  cursor: pointer;
  border-radius: 4px;
  background: #f0f3ff 0% 0% no-repeat padding-box;
  padding: 4px 10px;
  padding-left: 4px;
  text-align: left;
  font-family: 'Poppins';
  font-size: 13px;
  font-weight: 500;
  color: #93a2dd;
}

.icon__container {
  path {
    fill: $svg-icon;
  }
  circle {
    fill: $svg-icon;
  }
  .svg-rect {
    fill: $svg-icon;
  }
}

.dashboard-wrapper-flex {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.column-1 {
  flex-basis: calc(33% - 10px);

  &--container {
    display: flex;
    flex-direction: column;
  }
}

.column-2 {
  flex-basis: calc(66% - 5px);
}

.column-3 {
  flex-basis: 100%;
}

.column-half {
  flex-basis: 50%;
}
