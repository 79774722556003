button {
  @include button-reset;
}

.btn {
  cursor: pointer;
  font-size: 13px;
  line-height: 20px;
  border-radius: 3px;
  padding: 5px 15px;
  border: 1px solid #e2e5ec;
  color: #595d6e;
  transition: 0.2s;
  &:hover {
    border-color: #595d6e;
  }
  &:active {
    transform: scale(0.95);
  }
}
