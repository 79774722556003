$nav-background-dark: #1a1a27;
$nav-background-light: #1e1e2d;
$nav-item: #a2a5b9;
$nav-category: #4c4e6f;
$purple-light: #ae22ff;
$purple-dark: #6700a2;
$tab-text-active: #48465b;
$dashboard-background: #f2f3f8;
$tab-active: rgba(255, 255, 255, 1);
$blue: #567cfb;
$table-head: #595d6e;
$separator: #ebedf2;
$label-green: #0abbbb;
$svg-icon: rgb(93, 120, 255);
$bin: #fd397a;
$label-form: #646c9a;
$input-form-border: #e2e5ec;
$green: #0abe8c;
