.page-loader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #f2f3f8;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 12321;
}
