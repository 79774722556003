.nav-category {
  height: 40px;
  padding: 12px 28px;
  cursor: default;
}

.nav-item {
  display: flex;
  align-items: center;
  padding: 0 28px;
  height: 40px;
  transition: 0.3s;
  &__button {
    transition: 0.3s;
  }
  path[fill] {
    transition: 0.3s;
    fill: #494b74;
  }
  .svg-rect {
    transition: 0.3s;
    fill: #494b74;
  }
  &:hover {
    background-color: $nav-background-dark;
    path {
      fill: #5d78ff !important;
    }
    .svg-rect {
      fill: #5d78ff !important;
    }
    .nav-item__button {
      opacity: 0.7;
    }
  }
  &__title {
    flex-grow: 1;
    cursor: default;
    margin-left: 12px;
    transition: 0.3s;
    height: 100%;
    padding: 10px 0;
    line-height: 20px;
    &:hover {
      color: lighten($nav-item, 20%);
    }
  }
  &__button {
    width: 16px;
    height: 16px;
    opacity: 0.2;
    border-radius: 100%;
    @include button-reset;
    border: 1px solid #5d78ff;
    position: relative;
    &:before {
      @include plus-rectangle;
      transform: translate(-50%, -50%);
    }
    &:after {
      @include plus-rectangle;
      transform: translate(-50%, -50%) rotate(90deg);
    }
  }
}

.sidebar {
  height: 100vh;
  position: fixed;
  width: 265px;
  left: 0;
  top: 0;
  background-color: $nav-background-light;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }
  &__header {
    display: flex;
    background-color: $nav-background-dark;
    height: 60px;
    align-items: center;
    justify-content: space-between;
    padding: 20px 28px;
    transition: 0.3;
    path[fill] {
      transition: 0.3s;
    }
    &:hover {
      .arrow {
        fill: #5d78ff;
      }
    }
  }
  &__logo {
    line-height: 0;
    img {
      max-width: 100px;
    }
    &__wrapper {
      display: flex;
      align-items: center;
    }
  }
}
