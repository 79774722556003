@import url(https://fonts.googleapis.com/css?family=Poppins:300,400,500,600&display=swap);
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block; }

body {
  line-height: 1; }

ol,
ul {
  list-style: none; }

blockquote,
q {
  quotes: none; }

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

* {
  box-sizing: border-box; }

body {
  font-family: 'Poppins', sans-serif;
  font-size: 13px;
  line-height: 20px; }

.m-t-0 {
  margin-top: 0px!important; }

.m-t-1 {
  margin-top: 1px!important; }

.m-t-2 {
  margin-top: 2px!important; }

.m-t-3 {
  margin-top: 3px!important; }

.m-t-4 {
  margin-top: 4px!important; }

.m-t-5 {
  margin-top: 5px!important; }

.m-t-6 {
  margin-top: 6px!important; }

.m-t-7 {
  margin-top: 7px!important; }

.m-t-8 {
  margin-top: 8px!important; }

.m-t-9 {
  margin-top: 9px!important; }

.m-t-10 {
  margin-top: 10px!important; }

.m-t-11 {
  margin-top: 11px!important; }

.m-t-12 {
  margin-top: 12px!important; }

.m-t-13 {
  margin-top: 13px!important; }

.m-t-14 {
  margin-top: 14px!important; }

.m-t-15 {
  margin-top: 15px!important; }

.m-t-16 {
  margin-top: 16px!important; }

.m-t-17 {
  margin-top: 17px!important; }

.m-t-18 {
  margin-top: 18px!important; }

.m-t-19 {
  margin-top: 19px!important; }

.m-t-20 {
  margin-top: 20px!important; }

.m-t-21 {
  margin-top: 21px!important; }

.m-t-22 {
  margin-top: 22px!important; }

.m-t-23 {
  margin-top: 23px!important; }

.m-t-24 {
  margin-top: 24px!important; }

.m-t-25 {
  margin-top: 25px!important; }

.m-t-26 {
  margin-top: 26px!important; }

.m-t-27 {
  margin-top: 27px!important; }

.m-t-28 {
  margin-top: 28px!important; }

.m-t-29 {
  margin-top: 29px!important; }

.m-t-30 {
  margin-top: 30px!important; }

.m-t-31 {
  margin-top: 31px!important; }

.m-t-32 {
  margin-top: 32px!important; }

.m-t-33 {
  margin-top: 33px!important; }

.m-t-34 {
  margin-top: 34px!important; }

.m-t-35 {
  margin-top: 35px!important; }

.m-t-36 {
  margin-top: 36px!important; }

.m-t-37 {
  margin-top: 37px!important; }

.m-t-38 {
  margin-top: 38px!important; }

.m-t-39 {
  margin-top: 39px!important; }

.m-t-40 {
  margin-top: 40px!important; }

.m-t-41 {
  margin-top: 41px!important; }

.m-t-42 {
  margin-top: 42px!important; }

.m-t-43 {
  margin-top: 43px!important; }

.m-t-44 {
  margin-top: 44px!important; }

.m-t-45 {
  margin-top: 45px!important; }

.m-t-46 {
  margin-top: 46px!important; }

.m-t-47 {
  margin-top: 47px!important; }

.m-t-48 {
  margin-top: 48px!important; }

.m-t-49 {
  margin-top: 49px!important; }

.m-t-50 {
  margin-top: 50px!important; }

.m-r-0 {
  margin-right: 0px!important; }

.m-r-1 {
  margin-right: 1px!important; }

.m-r-2 {
  margin-right: 2px!important; }

.m-r-3 {
  margin-right: 3px!important; }

.m-r-4 {
  margin-right: 4px!important; }

.m-r-5 {
  margin-right: 5px!important; }

.m-r-6 {
  margin-right: 6px!important; }

.m-r-7 {
  margin-right: 7px!important; }

.m-r-8 {
  margin-right: 8px!important; }

.m-r-9 {
  margin-right: 9px!important; }

.m-r-10 {
  margin-right: 10px!important; }

.m-r-11 {
  margin-right: 11px!important; }

.m-r-12 {
  margin-right: 12px!important; }

.m-r-13 {
  margin-right: 13px!important; }

.m-r-14 {
  margin-right: 14px!important; }

.m-r-15 {
  margin-right: 15px!important; }

.m-r-16 {
  margin-right: 16px!important; }

.m-r-17 {
  margin-right: 17px!important; }

.m-r-18 {
  margin-right: 18px!important; }

.m-r-19 {
  margin-right: 19px!important; }

.m-r-20 {
  margin-right: 20px!important; }

.m-r-21 {
  margin-right: 21px!important; }

.m-r-22 {
  margin-right: 22px!important; }

.m-r-23 {
  margin-right: 23px!important; }

.m-r-24 {
  margin-right: 24px!important; }

.m-r-25 {
  margin-right: 25px!important; }

.m-r-26 {
  margin-right: 26px!important; }

.m-r-27 {
  margin-right: 27px!important; }

.m-r-28 {
  margin-right: 28px!important; }

.m-r-29 {
  margin-right: 29px!important; }

.m-r-30 {
  margin-right: 30px!important; }

.m-r-31 {
  margin-right: 31px!important; }

.m-r-32 {
  margin-right: 32px!important; }

.m-r-33 {
  margin-right: 33px!important; }

.m-r-34 {
  margin-right: 34px!important; }

.m-r-35 {
  margin-right: 35px!important; }

.m-r-36 {
  margin-right: 36px!important; }

.m-r-37 {
  margin-right: 37px!important; }

.m-r-38 {
  margin-right: 38px!important; }

.m-r-39 {
  margin-right: 39px!important; }

.m-r-40 {
  margin-right: 40px!important; }

.m-r-41 {
  margin-right: 41px!important; }

.m-r-42 {
  margin-right: 42px!important; }

.m-r-43 {
  margin-right: 43px!important; }

.m-r-44 {
  margin-right: 44px!important; }

.m-r-45 {
  margin-right: 45px!important; }

.m-r-46 {
  margin-right: 46px!important; }

.m-r-47 {
  margin-right: 47px!important; }

.m-r-48 {
  margin-right: 48px!important; }

.m-r-49 {
  margin-right: 49px!important; }

.m-r-50 {
  margin-right: 50px!important; }

.m-b-0 {
  margin-bottom: 0px!important; }

.m-b-1 {
  margin-bottom: 1px!important; }

.m-b-2 {
  margin-bottom: 2px!important; }

.m-b-3 {
  margin-bottom: 3px!important; }

.m-b-4 {
  margin-bottom: 4px!important; }

.m-b-5 {
  margin-bottom: 5px!important; }

.m-b-6 {
  margin-bottom: 6px!important; }

.m-b-7 {
  margin-bottom: 7px!important; }

.m-b-8 {
  margin-bottom: 8px!important; }

.m-b-9 {
  margin-bottom: 9px!important; }

.m-b-10 {
  margin-bottom: 10px!important; }

.m-b-11 {
  margin-bottom: 11px!important; }

.m-b-12 {
  margin-bottom: 12px!important; }

.m-b-13 {
  margin-bottom: 13px!important; }

.m-b-14 {
  margin-bottom: 14px!important; }

.m-b-15 {
  margin-bottom: 15px!important; }

.m-b-16 {
  margin-bottom: 16px!important; }

.m-b-17 {
  margin-bottom: 17px!important; }

.m-b-18 {
  margin-bottom: 18px!important; }

.m-b-19 {
  margin-bottom: 19px!important; }

.m-b-20 {
  margin-bottom: 20px!important; }

.m-b-21 {
  margin-bottom: 21px!important; }

.m-b-22 {
  margin-bottom: 22px!important; }

.m-b-23 {
  margin-bottom: 23px!important; }

.m-b-24 {
  margin-bottom: 24px!important; }

.m-b-25 {
  margin-bottom: 25px!important; }

.m-b-26 {
  margin-bottom: 26px!important; }

.m-b-27 {
  margin-bottom: 27px!important; }

.m-b-28 {
  margin-bottom: 28px!important; }

.m-b-29 {
  margin-bottom: 29px!important; }

.m-b-30 {
  margin-bottom: 30px!important; }

.m-b-31 {
  margin-bottom: 31px!important; }

.m-b-32 {
  margin-bottom: 32px!important; }

.m-b-33 {
  margin-bottom: 33px!important; }

.m-b-34 {
  margin-bottom: 34px!important; }

.m-b-35 {
  margin-bottom: 35px!important; }

.m-b-36 {
  margin-bottom: 36px!important; }

.m-b-37 {
  margin-bottom: 37px!important; }

.m-b-38 {
  margin-bottom: 38px!important; }

.m-b-39 {
  margin-bottom: 39px!important; }

.m-b-40 {
  margin-bottom: 40px!important; }

.m-b-41 {
  margin-bottom: 41px!important; }

.m-b-42 {
  margin-bottom: 42px!important; }

.m-b-43 {
  margin-bottom: 43px!important; }

.m-b-44 {
  margin-bottom: 44px!important; }

.m-b-45 {
  margin-bottom: 45px!important; }

.m-b-46 {
  margin-bottom: 46px!important; }

.m-b-47 {
  margin-bottom: 47px!important; }

.m-b-48 {
  margin-bottom: 48px!important; }

.m-b-49 {
  margin-bottom: 49px!important; }

.m-b-50 {
  margin-bottom: 50px!important; }

.m-l-0 {
  margin-left: 0px!important; }

.m-l-1 {
  margin-left: 1px!important; }

.m-l-2 {
  margin-left: 2px!important; }

.m-l-3 {
  margin-left: 3px!important; }

.m-l-4 {
  margin-left: 4px!important; }

.m-l-5 {
  margin-left: 5px!important; }

.m-l-6 {
  margin-left: 6px!important; }

.m-l-7 {
  margin-left: 7px!important; }

.m-l-8 {
  margin-left: 8px!important; }

.m-l-9 {
  margin-left: 9px!important; }

.m-l-10 {
  margin-left: 10px!important; }

.m-l-11 {
  margin-left: 11px!important; }

.m-l-12 {
  margin-left: 12px!important; }

.m-l-13 {
  margin-left: 13px!important; }

.m-l-14 {
  margin-left: 14px!important; }

.m-l-15 {
  margin-left: 15px!important; }

.m-l-16 {
  margin-left: 16px!important; }

.m-l-17 {
  margin-left: 17px!important; }

.m-l-18 {
  margin-left: 18px!important; }

.m-l-19 {
  margin-left: 19px!important; }

.m-l-20 {
  margin-left: 20px!important; }

.m-l-21 {
  margin-left: 21px!important; }

.m-l-22 {
  margin-left: 22px!important; }

.m-l-23 {
  margin-left: 23px!important; }

.m-l-24 {
  margin-left: 24px!important; }

.m-l-25 {
  margin-left: 25px!important; }

.m-l-26 {
  margin-left: 26px!important; }

.m-l-27 {
  margin-left: 27px!important; }

.m-l-28 {
  margin-left: 28px!important; }

.m-l-29 {
  margin-left: 29px!important; }

.m-l-30 {
  margin-left: 30px!important; }

.m-l-31 {
  margin-left: 31px!important; }

.m-l-32 {
  margin-left: 32px!important; }

.m-l-33 {
  margin-left: 33px!important; }

.m-l-34 {
  margin-left: 34px!important; }

.m-l-35 {
  margin-left: 35px!important; }

.m-l-36 {
  margin-left: 36px!important; }

.m-l-37 {
  margin-left: 37px!important; }

.m-l-38 {
  margin-left: 38px!important; }

.m-l-39 {
  margin-left: 39px!important; }

.m-l-40 {
  margin-left: 40px!important; }

.m-l-41 {
  margin-left: 41px!important; }

.m-l-42 {
  margin-left: 42px!important; }

.m-l-43 {
  margin-left: 43px!important; }

.m-l-44 {
  margin-left: 44px!important; }

.m-l-45 {
  margin-left: 45px!important; }

.m-l-46 {
  margin-left: 46px!important; }

.m-l-47 {
  margin-left: 47px!important; }

.m-l-48 {
  margin-left: 48px!important; }

.m-l-49 {
  margin-left: 49px!important; }

.m-l-50 {
  margin-left: 50px!important; }

.m-x-0 {
  margin: 0px!important; }

.m-x-1 {
  margin: 1px!important; }

.m-x-2 {
  margin: 2px!important; }

.m-x-3 {
  margin: 3px!important; }

.m-x-4 {
  margin: 4px!important; }

.m-x-5 {
  margin: 5px!important; }

.m-x-6 {
  margin: 6px!important; }

.m-x-7 {
  margin: 7px!important; }

.m-x-8 {
  margin: 8px!important; }

.m-x-9 {
  margin: 9px!important; }

.m-x-10 {
  margin: 10px!important; }

.m-x-11 {
  margin: 11px!important; }

.m-x-12 {
  margin: 12px!important; }

.m-x-13 {
  margin: 13px!important; }

.m-x-14 {
  margin: 14px!important; }

.m-x-15 {
  margin: 15px!important; }

.m-x-16 {
  margin: 16px!important; }

.m-x-17 {
  margin: 17px!important; }

.m-x-18 {
  margin: 18px!important; }

.m-x-19 {
  margin: 19px!important; }

.m-x-20 {
  margin: 20px!important; }

.m-x-21 {
  margin: 21px!important; }

.m-x-22 {
  margin: 22px!important; }

.m-x-23 {
  margin: 23px!important; }

.m-x-24 {
  margin: 24px!important; }

.m-x-25 {
  margin: 25px!important; }

.m-x-26 {
  margin: 26px!important; }

.m-x-27 {
  margin: 27px!important; }

.m-x-28 {
  margin: 28px!important; }

.m-x-29 {
  margin: 29px!important; }

.m-x-30 {
  margin: 30px!important; }

.m-x-31 {
  margin: 31px!important; }

.m-x-32 {
  margin: 32px!important; }

.m-x-33 {
  margin: 33px!important; }

.m-x-34 {
  margin: 34px!important; }

.m-x-35 {
  margin: 35px!important; }

.m-x-36 {
  margin: 36px!important; }

.m-x-37 {
  margin: 37px!important; }

.m-x-38 {
  margin: 38px!important; }

.m-x-39 {
  margin: 39px!important; }

.m-x-40 {
  margin: 40px!important; }

.m-x-41 {
  margin: 41px!important; }

.m-x-42 {
  margin: 42px!important; }

.m-x-43 {
  margin: 43px!important; }

.m-x-44 {
  margin: 44px!important; }

.m-x-45 {
  margin: 45px!important; }

.m-x-46 {
  margin: 46px!important; }

.m-x-47 {
  margin: 47px!important; }

.m-x-48 {
  margin: 48px!important; }

.m-x-49 {
  margin: 49px!important; }

.m-x-50 {
  margin: 50px!important; }

.nav-item {
  color: #a2a5b9; }

a {
  text-decoration: none;
  color: inherit; }

p {
  font-size: 13px;
  line-height: 20px;
  font-weight: 300;
  color: #595d6e; }

.nav-category {
  color: #4c4e6f;
  text-transform: uppercase; }

.navbar-text {
  font-size: 12px;
  margin: 0 5px;
  color: #fff; }

.breadcrumb {
  color: #74788d;
  font-size: 10px; }

.heading {
  font-size: 15px;
  color: #48465b;
  font-weight: 500; }

.search-text {
  color: #4950577c;
  font-weight: 400; }

.table-head th {
  color: #595d6e;
  font-weight: 500;
  text-align: left;
  padding: 15px; }

.table-body td {
  color: #595d6e;
  font-weight: 400;
  text-align: left; }
  .table-body td.align-right {
    text-align: right; }

.pagination-info {
  margin-left: 14px;
  color: #646c9a;
  font-weight: 100; }

.label-form {
  color: #646c9a; }

.platform-name {
  color: #595d6e;
  font-weight: 100;
  margin-right: 10px; }

.country-label {
  color: #a2a5b9;
  font-weight: 100; }

.popup-text {
  padding: 25px 30px;
  padding-bottom: 37px;
  color: #595d6e;
  font-weight: 100; }
  .popup-text strong {
    font-weight: 600; }

button {
  padding: 0;
  outline: none;
  background: none;
  border: none;
  cursor: pointer; }

.btn {
  cursor: pointer;
  font-size: 13px;
  line-height: 20px;
  border-radius: 3px;
  padding: 5px 15px;
  border: 1px solid #e2e5ec;
  color: #595d6e;
  transition: 0.2s; }
  .btn:hover {
    border-color: #595d6e; }
  .btn:active {
    -webkit-transform: scale(0.95);
            transform: scale(0.95); }

.table {
  width: 100%;
  background-color: transparent; }
  .table__header tr {
    border-bottom: 1px solid #ebedf2; }
  .table__header th {
    padding: 18px 0px; }
    .table__header th:first-child {
      padding-left: 28px; }
  .table__body .row_link {
    cursor: pointer; }
  .table__body tr {
    border-bottom: 1px solid #ebedf2; }
    .table__body tr:hover {
      background-color: #f9f9f9; }
  .table__body td {
    padding: 20px 0px; }
  .table__body tr td:first-child {
    padding-left: 28px; }
    .table__body tr td:first-child.child {
      position: relative; }
      .table__body tr td:first-child.child--1 {
        padding-left: 63px; }
      .table__body tr td:first-child.child--2 {
        padding-left: 98px; }
  .table__body tr td:last-child {
    padding-right: 28px; }
  .table__body tr .category-span {
    position: relative; }
    .table__body tr .category-span::before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: -44px;
      width: 23px;
      height: 2px;
      background-color: #597dff;
      opacity: 0.4;
      top: 49%; }
    .table__body tr .category-span--hide::before {
      display: none; }
  .table__double-label {
    display: inline;
    margin-right: 8px;
    font-family: 'Poppins', sans-serif; }
  .table__label {
    background-color: #ececec;
    padding: 2px 8px;
    margin-right: 8px;
    font-size: 11px;
    line-height: 16px;
    border-radius: 4px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500; }
    .table__label--green {
      color: #0abbbb;
      background-color: rgba(10, 187, 187, 0.2); }
    .table__label--red {
      color: #fd3939;
      background-color: rgba(253, 57, 57, 0.2); }
    .table__label--yellow {
      color: #ffb822;
      background-color: rgba(255, 184, 34, 0.2); }
    .table__label--violet {
      color: #6744f1;
      background-color: rgba(103, 68, 241, 0.2); }
  .table__label-left {
    background-color: #ececec;
    padding: 3px 8px;
    font-size: 11px;
    border-radius: 4px 0 0 4px;
    font-weight: 500; }
    .table__label-left--green {
      color: #0abbbb;
      background-color: rgba(10, 187, 187, 0.2); }
    .table__label-left--red {
      color: #fd3939;
      background-color: rgba(253, 57, 57, 0.2); }
    .table__label-left--yellow {
      color: #ffb822;
      background-color: rgba(255, 184, 34, 0.2); }
    .table__label-left--violet {
      color: #6744f1;
      background-color: rgba(103, 68, 241, 0.2); }
  .table__label-right {
    padding: 2px 8px;
    font-size: 11px;
    border: 1px solid #ececec;
    border-radius: 0 4px 4px 0;
    font-weight: 500; }
    .table__label-right--orange {
      color: #ff5a00;
      border-color: #ff5a00; }
    .table__label-right--blue {
      color: #0064d2;
      border-color: #0064d2; }
    .table__label-right--green {
      color: #99cc00;
      border-color: #99cc00; }
  .table__platform {
    margin-right: 10px;
    line-height: 16px;
    padding: 2px 8px;
    font-size: 11px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    border: 1px solid #ececec;
    border-radius: 4px; }
    .table__platform--orange {
      color: #ff5a00;
      border-color: #ff5a00; }
    .table__platform--blue {
      color: #0064d2;
      border-color: #0064d2; }
    .table__platform--green {
      color: #99cc00;
      border-color: #99cc00; }
  .table__icon {
    margin-right: 10px;
    cursor: pointer; }
    .table__icon path {
      fill: #91a0da; }
    .table__icon--purple svg {
      -webkit-transform: translateY(-2px) scale(1.2);
              transform: translateY(-2px) scale(1.2); }
    .table__icon--purple path {
      fill: #fd397a; }

.page-loader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #f2f3f8;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  z-index: 12321; }

.nav-category {
  height: 40px;
  padding: 12px 28px;
  cursor: default; }

.nav-item {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  padding: 0 28px;
  height: 40px;
  transition: 0.3s; }
  .nav-item__button {
    transition: 0.3s; }
  .nav-item path[fill] {
    transition: 0.3s;
    fill: #494b74; }
  .nav-item .svg-rect {
    transition: 0.3s;
    fill: #494b74; }
  .nav-item:hover {
    background-color: #1a1a27; }
    .nav-item:hover path {
      fill: #5d78ff !important; }
    .nav-item:hover .svg-rect {
      fill: #5d78ff !important; }
    .nav-item:hover .nav-item__button {
      opacity: 0.7; }
  .nav-item__title {
    -webkit-flex-grow: 1;
            flex-grow: 1;
    cursor: default;
    margin-left: 12px;
    transition: 0.3s;
    height: 100%;
    padding: 10px 0;
    line-height: 20px; }
    .nav-item__title:hover {
      color: #dcdde5; }
  .nav-item__button {
    width: 16px;
    height: 16px;
    opacity: 0.2;
    border-radius: 100%;
    padding: 0;
    outline: none;
    background: none;
    border: none;
    cursor: pointer;
    border: 1px solid #5d78ff;
    position: relative; }
    .nav-item__button:before {
      content: '';
      position: absolute;
      width: 1px;
      height: 7px;
      background-color: #5d78ff;
      z-index: 5;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%); }
    .nav-item__button:after {
      content: '';
      position: absolute;
      width: 1px;
      height: 7px;
      background-color: #5d78ff;
      z-index: 5;
      -webkit-transform: translate(-50%, -50%) rotate(90deg);
              transform: translate(-50%, -50%) rotate(90deg); }

.sidebar {
  height: 100vh;
  position: fixed;
  width: 265px;
  left: 0;
  top: 0;
  background-color: #1e1e2d;
  overflow-y: scroll; }
  .sidebar::-webkit-scrollbar {
    width: 0;
    background: transparent; }
  .sidebar__header {
    display: -webkit-flex;
    display: flex;
    background-color: #1a1a27;
    height: 60px;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    padding: 20px 28px;
    transition: 0.3; }
    .sidebar__header path[fill] {
      transition: 0.3s; }
    .sidebar__header:hover .arrow {
      fill: #5d78ff; }
  .sidebar__logo {
    line-height: 0; }
    .sidebar__logo img {
      max-width: 100px; }
    .sidebar__logo__wrapper {
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center; }

.main-container {
  margin-left: 265px;
  width: calc(100% - 265px); }

.main-navbar {
  width: calc(100% - 265px);
  position: fixed;
  z-index: 800;
  top: 0;
  background: linear-gradient(180deg, #ae22ff 0%, #6700a2 100%) 0% 0%; }
  .main-navbar__top {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    padding: 0 8px;
    padding-top: 8px; }
  .main-navbar__bottom {
    position: relative;
    padding: 0 8px;
    min-height: 48px;
    padding-top: 10px;
    z-index: 10;
    overflow: hidden; }
    .main-navbar__bottom:after {
      content: '';
      position: absolute;
      width: 100%;
      background-color: black;
      top: 0;
      left: 0;
      opacity: 0.1;
      z-index: -1; }
    .main-navbar__bottom .tab-list {
      display: -webkit-flex;
      display: flex; }
      .main-navbar__bottom .tab-list__text {
        white-space: nowrap; }
      .main-navbar__bottom .tab-list__item {
        -webkit-flex-basis: 198px;
                flex-basis: 198px;
        cursor: default;
        border-radius: 4px 4px 0px 0px;
        background-color: rgba(255, 255, 255, 0.2);
        margin-right: 4px;
        transition: 0.3s; }
        .main-navbar__bottom .tab-list__item a {
          padding: 4px 10px;
          display: -webkit-flex;
          display: flex;
          -webkit-align-items: center;
                  align-items: center; }
        .main-navbar__bottom .tab-list__item:hover {
          background-color: rgba(255, 255, 255, 0.3); }
        .main-navbar__bottom .tab-list__item--active {
          background-color: white; }
          .main-navbar__bottom .tab-list__item--active:hover {
            background-color: white; }
          .main-navbar__bottom .tab-list__item--active path {
            fill: #5d78ff; }
          .main-navbar__bottom .tab-list__item--active .svg-rect {
            fill: #5d78ff; }
          .main-navbar__bottom .tab-list__item--active .tab-list__text {
            color: #48465b; }
          .main-navbar__bottom .tab-list__item--active .tab-list__close:before {
            background-color: #48465b; }
          .main-navbar__bottom .tab-list__item--active .tab-list__close:after {
            background-color: #48465b; }
      .main-navbar__bottom .tab-list__close {
        padding: 0;
        outline: none;
        background: none;
        border: none;
        cursor: pointer;
        width: 30px;
        height: 30px;
        margin-left: auto;
        position: relative;
        cursor: pointer; }
        .main-navbar__bottom .tab-list__close:before {
          content: '';
          width: 1px;
          height: 12px;
          background-color: white;
          position: absolute;
          -webkit-transform: translate(-50%, -50%) rotate(45deg);
                  transform: translate(-50%, -50%) rotate(45deg); }
        .main-navbar__bottom .tab-list__close:after {
          content: '';
          width: 1px;
          height: 12px;
          background-color: white;
          position: absolute;
          -webkit-transform: translate(-50%, -50%) rotate(-45deg);
                  transform: translate(-50%, -50%) rotate(-45deg); }
  .main-navbar__app-name {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    cursor: default; }

.nav-button {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  padding: 0;
  outline: none;
  background: none;
  border: none;
  cursor: pointer;
  transition: 0.3s; }
  .nav-button:hover .nav-button__logo::after {
    opacity: 0.2; }
  .nav-button__logo {
    position: relative; }
    .nav-button__logo--right {
      -webkit-order: 2;
              order: 2; }
    .nav-button__logo:after {
      content: '';
      position: absolute;
      transition: 0.3s;
      top: 0;
      left: 0;
      border-radius: 5px;
      width: 24px;
      height: 24px;
      background-color: #fff;
      opacity: 0.1; }

.breadcrumbs {
  height: 30px;
  padding: 8px 8px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  width: 100%;
  margin-top: 5px;
  background-color: #fff;
  box-shadow: 0px 0px 13px #523f690c; }
  .breadcrumbs__button {
    position: relative;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    border-radius: 2px;
    width: 16px;
    height: 16px;
    background: #f0f3ff 0% 0% no-repeat padding-box; }
    .breadcrumbs__button svg {
      max-width: 10px; }
  .breadcrumbs li {
    position: relative; }
  .breadcrumbs li:after {
    content: '';
    position: absolute;
    width: 1px;
    height: 5px;
    background-color: #a2a5b9;
    top: 6px;
    right: 10px;
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg); }
  .breadcrumbs li:before {
    content: '';
    position: absolute;
    width: 1px;
    height: 5px;
    background-color: #a2a5b9;
    top: 9px;
    right: 10px;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg); }
  .breadcrumbs li:last-child:before, .breadcrumbs li:last-child:after {
    display: none; }
  .breadcrumbs__list {
    display: -webkit-flex;
    display: flex;
    margin-left: 15px; }
  .breadcrumbs__item {
    margin-right: 21px;
    padding: 0;
    outline: none;
    background: none;
    border: none;
    cursor: pointer;
    cursor: pointer; }

.dashboard {
  width: 100%;
  background-color: white;
  box-shadow: 0px 0px 13px #523f690c;
  border-radius: 5px;
  margin: 0 0 20px;
  margin-bottom: 8px; }
  .dashboard--small {
    -webkit-flex-basis: 49.2%;
            flex-basis: 49.2%; }
  .dashboard__button-section {
    padding: 20px 30px; }
  .dashboard__list-item {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    padding: 20px 30px;
    position: relative; }
    .dashboard__list-item .country {
      margin-right: auto; }
      .dashboard__list-item .country-label--hidden {
        visibility: hidden; }
    .dashboard__list-item .no-margin {
      margin: 0; }
    .dashboard__list-item:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: #ebedf2; }
  .dashboard__form {
    padding: 30px; }
  .dashboard__channel-label {
    padding: 30px;
    padding-top: 0; }
    .dashboard__channel-label .channel-label-text {
      margin-bottom: 11px; }
  .dashboard__header {
    display: -webkit-flex;
    display: flex;
    padding: 20px 30px;
    -webkit-align-items: center;
            align-items: center;
    position: relative; }
    .dashboard__header:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: #ebedf2; }
    .dashboard__header--edit {
      padding: 18px 30px; }
  .dashboard__title {
    margin-right: auto;
    margin-left: 10px; }
  .dashboard__searchfield {
    padding: 18px 30px;
    display: -webkit-flex;
    display: flex; }
    .dashboard__searchfield .search-box {
      -webkit-flex: 1 1;
              flex: 1 1;
      padding: 10px;
      border: 1px solid #e2e5ec;
      border-radius: 4px; }

.dashboard-container {
  width: 100%;
  margin-top: 30px;
  min-height: calc(100vh - 30px);
  background-color: #f2f3f8;
  padding: 20px;
  padding-top: 80px;
  padding-bottom: 70px; }

.selectbox {
  border: none;
  cursor: pointer;
  border-radius: 4px;
  background: #f0f3ff 0% 0% no-repeat padding-box;
  padding: 4px 10px;
  padding-left: 4px;
  text-align: left;
  font-family: 'Poppins';
  font-size: 13px;
  font-weight: 500;
  color: #93a2dd; }

.icon__container path {
  fill: #5d78ff; }

.icon__container circle {
  fill: #5d78ff; }

.icon__container .svg-rect {
  fill: #5d78ff; }

.dashboard-wrapper-flex {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: flex-start;
          align-items: flex-start; }

.column-1 {
  -webkit-flex-basis: calc(33% - 10px);
          flex-basis: calc(33% - 10px); }
  .column-1--container {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column; }

.column-2 {
  -webkit-flex-basis: calc(66% - 5px);
          flex-basis: calc(66% - 5px); }

.column-3 {
  -webkit-flex-basis: 100%;
          flex-basis: 100%; }

.column-half {
  -webkit-flex-basis: 50%;
          flex-basis: 50%; }

.bottombar {
  height: 58px;
  width: calc(100% - 265px);
  background: transparent linear-gradient(2deg, #ffffff 0%, #ffffff 100%) 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 13px #523f690c;
  position: fixed;
  bottom: 0;
  margin-left: -20px;
  margin-right: 100px;
  padding: 20px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center; }
  .bottombar__btn-container--mr {
    margin-right: auto; }
  .bottombar__btn-container--ml {
    margin-left: 20px; }
  .bottombar__btn-container svg {
    -webkit-transform: translate(-5px, -1px);
            transform: translate(-5px, -1px); }

.form-row {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  width: calc(100% + 20px);
  margin-left: -10px; }
  .form-row .form-group {
    margin: 0 10px; }
  .form-row:last-child .form-group {
    margin-bottom: 0; }
  .form-row.grid-2 .form-group {
    -webkit-flex-basis: calc(50% - 10px);
            flex-basis: calc(50% - 10px); }
  .form-row.grid-3 .form-group {
    -webkit-flex-basis: calc(33.3% - 20px);
            flex-basis: calc(33.3% - 20px); }

.form-group {
  -webkit-flex-basis: 100%;
          flex-basis: 100%;
  max-width: 100%; }
  .form-group:not(:last-child) {
    margin-bottom: 20px; }
  .form-group label {
    display: inline-block;
    width: 100%;
    margin-bottom: 5px; }
  .form-group .inp-group {
    position: relative;
    overflow: hidden;
    display: -webkit-flex;
    display: flex; }
    .form-group .inp-group input {
      padding: 9px;
      border-radius: 4px;
      border: none;
      border: 1px solid #e2e5ec;
      width: 100%;
      outline: none; }
      .form-group .inp-group input[type='number']::-webkit-inner-spin-button, .form-group .inp-group input[type='number']::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0; }
    .form-group .inp-group .form-span {
      display: block;
      position: relative;
      white-space: nowrap;
      font-size: 13px;
      font-family: 'Poppins';
      color: #495057;
      top: 0;
      bottom: 0;
      background-color: #f7f8fa;
      border: 1px solid #e2e5ec;
      padding: 10px; }
    .form-group .inp-group .postfix {
      right: 0px;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      margin-left: -3px; }
      .form-group .inp-group .postfix-color {
        width: 16px;
        height: 16px;
        background-color: #44b2f1;
        border: 1px solid #e2e5ec; }
    .form-group .inp-group .prefix {
      z-index: 1;
      margin-right: -3px;
      left: 0px;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px; }
  .form-group.save-group {
    display: -webkit-flex;
    display: flex; }
    .form-group.save-group .btn-save {
      position: relative;
      width: 40px;
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: center;
              justify-content: center;
      -webkit-align-items: center;
              align-items: center;
      border-radius: 3px;
      margin-left: -5px;
      background-color: #0abe8c;
      box-shadow: 0px 3px 16px #0abe8c26;
      transition: 0.2s; }
      .form-group.save-group .btn-save svg {
        max-width: 20px; }
      .form-group.save-group .btn-save[disabled] {
        opacity: 0;
        pointer-events: none; }
      .form-group.save-group .btn-save:hover {
        background-color: rgba(10, 190, 140, 0.6); }
      .form-group.save-group .btn-save:active {
        -webkit-transform: scale(0.95);
                transform: scale(0.95); }
    .form-group.save-group .inp-group input {
      max-width: 90px;
      min-width: 75px;
      padding-right: 30px; }
    .form-group.save-group .inp-group::before {
      content: '';
      background-position: center;
      background-size: contain;
      position: absolute;
      top: 50%;
      right: 15px;
      -webkit-transform: translateY(-50%);
              transform: translateY(-50%); }
    .form-group.save-group .inp-group.success::before {
      width: 9px;
      height: 9px;
      background-image: url(/static/media/inp-success.54a1c6a2.svg); }
    .form-group.save-group .inp-group.success input {
      border-color: #0abb87; }
    .form-group.save-group .inp-group.error::before {
      width: 11px;
      height: 9px;
      background-image: url(/static/media/inp-error.034d8981.svg); }
    .form-group.save-group .inp-group.error input {
      border-color: #fd397a; }

.input-disabled {
  background-color: #f7f8fa;
  font-size: 13px;
  font-family: 'Poppins'; }

.form-row__select {
  background-color: white;
  cursor: pointer;
  width: 100%;
  padding: 11px;
  border-radius: 4px;
  border: none;
  border: 1px solid #e2e5ec;
  color: #495057;
  font-size: 13px;
  font-family: 'Poppins', sans-serif;
  /* Firefox */
  -webkit-appearance: none;
  /* Safari and Chrome */
  appearance: none; }

.popup-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: #000000b4;
  z-index: 1000;
  display: -webkit-flex;
  display: flex; }

.popup {
  max-width: 424px;
  margin: auto;
  width: 100%;
  background-color: white;
  border-radius: 5px;
  opacity: 0;
  transition: 0.2s; }
  .popup.active {
    opacity: 1; }
  .popup__header {
    padding: 18px 30px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    position: relative; }
    .popup__header button:only-child {
      margin-left: auto; }
    .popup__header:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: #ebedf2; }
  .popup__footer {
    text-align: end;
    padding: 10px 15px 10px 15px;
    border-top: 1px solid #ebedf2; }
    .popup__footer button {
      margin-left: 11px; }
    .popup__footer img {
      vertical-align: middle; }
  .popup.popup__image {
    max-width: 650px; }
    .popup.popup__image .popup__body {
      padding: 0 30px 30px;
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center;
      -webkit-justify-content: center;
              justify-content: center; }
      .popup.popup__image .popup__body img {
        max-width: 100%; }
    .popup.popup__image .popup__header {
      padding: 18px 30px;
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center;
      -webkit-justify-content: flex-end;
              justify-content: flex-end; }
      .popup.popup__image .popup__header:after {
        display: none; }
  .popup.popup__login .popup__body {
    padding: 20px; }
    .popup.popup__login .popup__body .login_button {
      text-align: center; }

.categories {
  color: #595d6e;
  padding: 20px;
  padding-top: 0px; }
  .categories__item {
    width: 100%; }
  .categories ul.child--1 {
    position: relative; }
    .categories ul.child--1::before {
      content: '';
      position: absolute;
      z-index: 200;
      top: 10px;
      bottom: 5px;
      left: 0;
      border-left: 1px dashed rgba(89, 125, 255, 0.4);
      -webkit-transform: translate(2px, -30px);
              transform: translate(2px, -30px); }
    .categories ul.child--1 > li > table {
      position: relative; }
      .categories ul.child--1 > li > table::before {
        content: '';
        z-index: 200;
        top: 47%;
        left: 23px;
        position: absolute;
        width: 16px;
        -webkit-transform: translateY(-50%);
                transform: translateY(-50%);
        border-bottom: 1px dashed rgba(89, 125, 255, 0.4); }
    .categories ul.child--1 > li tr td:first-child {
      padding-left: 60px; }
  .categories ul.child--2 {
    position: relative; }
    .categories ul.child--2::before {
      content: '';
      z-index: 200;
      position: absolute;
      top: 10px;
      bottom: 5px;
      left: 30px;
      border-left: 1px dashed rgba(89, 125, 255, 0.4);
      -webkit-transform: translate(2px, -30px);
              transform: translate(2px, -30px); }
    .categories ul.child--2 > li {
      position: relative; }
      .categories ul.child--2 > li::before {
        content: '';
        z-index: 200;
        top: 45%;
        left: 32px;
        position: absolute;
        width: 16px;
        -webkit-transform: translateY(-50%);
                transform: translateY(-50%);
        border-bottom: 1px dashed rgba(89, 125, 255, 0.4); }
    .categories ul.child--2 > li tr td:first-child {
      padding-left: 90px; }
  .categories table {
    width: calc(100% + 40px);
    margin-left: -20px;
    border-bottom: 1px solid #ebedf2; }
    .categories table td {
      padding: 20px 0; }
    .categories table tr td:first-child {
      padding-left: 30px;
      width: 380px; }

.label {
  display: inline-block;
  font-size: 11px;
  line-height: 12px;
  letter-spacing: 0.81px;
  border-radius: 2px;
  padding: 4px 8px;
  background: transparent;
  color: #fff;
  font-weight: 300; }
  .label-sm {
    padding: 2px 4px;
    font-size: 10px; }
  .label-bold {
    font-weight: 600; }
  .label-round {
    border-radius: 4px; }
  .label-up {
    text-transform: uppercase; }
  .label--warning {
    background-color: #ffb822; }
  .label--info {
    background-color: #5D78FF; }
  .label--success {
    background-color: #0ABB87; }

.pagination {
  padding: 20px 30px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between; }
  .pagination-left {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center; }
  .pagination-box {
    cursor: pointer;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    color: #93a2dd;
    font-size: 13px;
    line-height: 20px;
    padding: 3px;
    font-weight: 500;
    border-radius: 4px;
    margin-right: 5px;
    background-color: #fbfbff;
    min-width: 30px;
    transition: 0.2s; }
    .pagination-box svg {
      max-width: 15px; }
    .pagination-box.active {
      background-color: #5d78ff;
      color: white; }
    .pagination-box.arrow {
      background-color: #f0f3ff; }
      .pagination-box.arrow path {
        fill: #93a2dd; }
      .pagination-box.arrow[disabled] {
        pointer-events: none;
        background-color: #fbfbff; }
        .pagination-box.arrow[disabled] path {
          fill: #dfe3f5; }

.heading.blocked {
  color: #fd397a; }
  .heading.blocked .error {
    margin-left: 20px;
    font-size: 20px;
    line-height: 25px;
    text-transform: uppercase; }

.supply-table.blocked {
  pointer-events: none;
  position: relative; }
  .supply-table.blocked::before {
    content: '';
    position: absolute;
    z-index: 2;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.2); }

.supply-table__header {
  padding: 25px 27px 20px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between; }
  .supply-table__header strong {
    font-weight: 500; }
  .supply-table__header__document-info {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center; }
    .supply-table__header__document-info p {
      margin-left: 50px; }

.supply-table tr {
  vertical-align: middle; }
  .supply-table tr td {
    padding: 0;
    font-weight: 300;
    vertical-align: middle;
    max-width: 300px;
    padding-right: 10px; }

.supply-table .number {
  width: 75px; }
  .supply-table .number span {
    display: inline-block;
    line-height: 17px;
    min-width: 17px;
    padding: 0 5px;
    text-align: center;
    border-radius: 50%; }
  .supply-table .number.success span {
    background-color: #daf5ed;
    color: #0abb87; }

.supply-table .image-box {
  width: 70px;
  border-radius: 4px;
  margin-right: 10px;
  font-size: 0;
  line-height: 0; }
  .supply-table .image-box .modal__trigger {
    width: 60px;
    height: 60px;
    border-radius: 4px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    overflow: hidden;
    position: relative; }
    .supply-table .image-box .modal__trigger::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(93, 120, 255, 0.8);
      opacity: 0;
      transition: 0.2s;
      z-index: 1; }
    .supply-table .image-box .modal__trigger::after {
      content: url(/static/media/zoom.0d1641e7.svg);
      position: absolute;
      z-index: 2;
      top: 50%;
      left: 50%;
      opacity: 0;
      transition: 0.2s;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%); }
    .supply-table .image-box .modal__trigger:hover::before, .supply-table .image-box .modal__trigger:hover::after {
      opacity: 1; }

